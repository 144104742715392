import React from 'react';
import { FacebookShareButton, TwitterShareButton } from 'react-share';
import './PostShare.css';

const formatSiteUrl = (siteUrl, pathPrefix, path) =>
  siteUrl + (pathPrefix === '/' ? '' : pathPrefix) + path;

// const getCover = post => {
//   const { cover } = post;
//   if (cover && cover.childImageSharp && cover.childImageSharp.original) {
//     return cover.childImageSharp.original.src;
//   }
//   return "";
// };

class PostShare extends React.Component {
  render() {
    const { postNode, postPath, config } = this.props;
    const post = postNode.frontmatter;
    const url = formatSiteUrl(config.siteUrl, config.pathPrefix, postPath);

    return (
      <section className="share">
        <h4>Share this post</h4>
        <div style={{ display: 'flex' }}>
          <TwitterShareButton url={url} title={post.title}>
            <div className="icon-twitter" style={{ fontSize: '1.4em' }}>
              <span className="hidden">Twitter</span>
            </div>
          </TwitterShareButton>
          <FacebookShareButton url={url} quote={post.title}>
            <div className="icon-facebook" style={{ fontSize: '1.4em' }}>
              <span className="hidden">Facebook</span>
            </div>
          </FacebookShareButton>
        </div>
      </section>
    );
  }
}

export default PostShare;
