import React, { Component } from 'react';
import classNames from 'classnames';
import Link from 'gatsby-link';
import { GatsbyImage, getImage } from 'gatsby-plugin-image';
import './ReadNext.css';

const ReadNextStory = (props) => {
  const { post } = props;
  if (post) {
    /** Limit words to 19 in GraphQL * */
    const { path, cover, title, excerpt } = post;
    const classes = classNames('read-next-story', props.className, {
      'no-cover': !cover,
    });

    const image = getImage(cover);

    return (
      <Link className={classes} to={path}>
        {image && (
          <GatsbyImage
            alt="Cover image"
            image={image}
            style={{
              objectFit: 'cover',
              position: 'absolute',
              top: 0,
              left: 0,
              width: '100%',
              height: '100%',
            }}
          />
        )}
        <section className="post">
          <h2>{title}</h2>
          <p>
            {excerpt}
            &hellip;
          </p>
        </section>
      </Link>
    );
  }
  return null;
};

/**
 * Links to Previous/Next posts
 */
class ReadNext extends Component {
  render() {
    const { next, prev } = this.props;
    return (
      <aside className="read-next">
        <ReadNextStory post={next} />
        <ReadNextStory className="prev" post={prev} />
      </aside>
    );
  }
}

export default ReadNext;
